/* eslint-disable no-unused-expressions */
/* eslint-disable quote-props */
/* eslint-disable no-useless-escape */

import { mapGetters, mapActions } from 'vuex'
import { isNotEmptyError, showVueToast } from '@/utils'

const CONFIG = {
  name: 'Full Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  facultyName: 'Faculty ',
  identityNumber: 'Identity Number ',
  majorName: 'Major ',
  universityName: 'University ',
  gpa: 'Gpa'
}

export default {
  name: 'scholarship',
  data: () => ({
    isModal: false,
    slideOne: true,
    programCode: 'TechChamp',
    formPayload: {
      name: '',
      email: '',
      phoneNumber: '',
      facultyName: '',
      identityNumber: '',
      majorName: '',
      universityName: '',
      gpa: ''
    },
    phoneInputType: 'number',
    identityInputType: 'number',
    errors: []
  }),
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/components/modal/Modal')
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  watch: {
    name(value) {
      value === '' ? (this.errors['name'] = "Full Name can't be empty!") : (this.errors['name'] = '')
    },
    email(value) {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      value === '' ? (this.errors['email'] = "Email can't be empty!") : (this.errors['email'] = '')
      !emailPattern.test(this.formPayload.email) ? (this.errors['email'] = 'Incorrect email format!') : (this.errors['email'] = '')
    },
    phoneNumber(value) {
      if (value.length >= 15) {
        this.formPayload.phoneNumber = value.slice(0, 15)
        this.phoneInputType = 'text'
      } else {
        this.phoneInputType = 'number'
      }
      if (value[0] !== '0' || value[1] !== '8') {
        this.errors['phoneNumber'] = 'Must start with 08xxxx'
      } else if (value.length < 10) {
        this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
      } else {
        this.errors['phoneNumber'] = ''
      }
    },
    identityNumber(value) {
      value === '' ? (this.errors['identityNumber'] = "identity Number can't be empty!") : (this.errors['identityNumber'] = '')
      if (value.length >= 20) {
        this.formPayload.identityNumber = value.slice(0, 20)
        this.identityInputType = 'text'
      } else {
        this.identityInputType = 'number'
      }
    },
    universityName(value) {
      value === '' ? (this.errors['universityName'] = "university Name can't be empty!") : (this.errors['universityName'] = '')
    },
    facultyName(value) {
      value === '' ? (this.errors['facultyName'] = "faculty Name can't be empty!") : (this.errors['facultyName'] = '')
    },
    majorName(value) {
      value === '' ? (this.errors['majorName'] = "major Name can't be empty!") : (this.errors['majorName'] = '')
    },
    gpa(value) {
      value === '' ? (this.errors['gpa'] = "GPA can't be empty!") : (this.errors['gpa'] = '')
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    videoLink() {
      return 'https://www.youtube.com/embed/FJbC68PHz0I'
    },
    isDisabled() {
      return !this.formPayload.name || !this.formPayload.email || !this.formPayload.phoneNumber
    },
    disabled() {
      return !this.formPayload.identityNumber || !this.formPayload.universityName || !this.formPayload.facultyName || !this.formPayload.majorName || !this.formPayload.gpa
    },
    name() {
      return this.formPayload.name
    },
    email() {
      return this.getOnlyText(this.formPayload.email)
    },
    phoneNumber() {
      return this.formPayload.phoneNumber
    },
    identityNumber() {
      return this.formPayload.identityNumber
    },
    universityName() {
      return this.formPayload.universityName
    },
    facultyName() {
      return this.formPayload.facultyName
    },
    majorName() {
      return this.formPayload.majorName
    },
    gpa() {
      return this.formPayload.gpa
    },
    activeLanguange() {
      return this.$i18n.locale
    },
    requirements() {
      return this.$i18n.messages[this.activeLanguange].techChamp.requirements.items
    },
    corporateTraining() {
      return this.$i18n.messages[this.activeLanguange].techChamp.help.items
    },
    registrationStep() {
      return this.$i18n.messages[this.activeLanguange].techChamp.registrationStep.items
    }
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('scholarship', ['postScholarship']),
    toRegister() {
      this.isModal = true
      this.slideOne = true
    },
    closeModal() {
      this.isModal = false
      this.formPayload = {
        name: '',
        email: '',
        phoneNumber: '',
        facultyName: '',
        identityNumber: '',
        majorName: '',
        universityName: '',
        gpa: ''
      }
    },
    getOnlyText(str) {
      var tmp = document.createElement('DIV')
      tmp.innerHTML = str
      return tmp.textContent.split(' ').join('') || tmp.innerText.split(' ').join('') || ''
    },
    validForm(isPage) {
      if (isPage) {
        let pass = true

        const error = isNotEmptyError(this.formPayload)
        this.errors = error.reduce(function (map, obj) {
          map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
          return map
        }, {})

        if (this.formPayload.phoneNumber === '') {
          this.errors['phoneNumber'] = "Phone Number can't be empty!"
          pass = false
        } else if (this.formPayload.phoneNumber[0] !== '0' || this.formPayload.phoneNumber[1] !== '8') {
          this.errors['phoneNumber'] = 'Must start with 08xxxx'
          pass = false
        } else if (this.formPayload.phoneNumber.length < 10) {
          this.errors['phoneNumber'] = 'Minimum 10 digits, Maximum 15 digits'
          pass = false
        }

        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (this.formPayload.email === '') {
          this.errors['email'] = "Email can't be empty!"
          pass = false
        } else if (!emailPattern.test(this.formPayload.email)) {
          this.errors['email'] = 'Incorrect email format!'
          pass = false
        }
        return pass
      }
    },
    onModalNextPage() {
      if (!this.validForm(true)) return null
      this.slideOne = false
    },
    onSubmit() {
      this.showLoading()
      const payload = {
        name: this.formPayload.name,
        email: this.getOnlyText(this.formPayload.email),
        customerId: 1,
        programCode: this.programCode,
        phoneNumber: this.formPayload.phoneNumber,
        additionalInfo: {
          facultyName: this.formPayload.facultyName,
          identityNumber: this.formPayload.identityNumber,
          majorName: this.formPayload.majorName,
          universityName: this.formPayload.universityName,
          gpa: Number(this.formPayload.gpa)
        }
      }
      this.postScholarship({
        payload
      })
        .then(() => {
          this.hideLoading()
          this.isModal = false
          this.$router.push('/thankyou-for-scholarship')
        })
        .catch((e) => {
          showVueToast('Email already have scholarship submission', 'error', 2000)
          this.hideLoading()
          this.isModal = false
        })
    }
  }
}
